<template>
  <v-row>
    <!-- Advanced usage  -->
    <v-col md="12">
      <base-card>
        <v-card-title>Liste der Klassen</v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-list-item
              v-for="course in courses"
              :key="course.id"
              :to="course.link"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="[course.iconClass]"
                  v-text="course.icon"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="course.title" />
                <v-list-item-subtitle v-text="course.subtitle" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Courses',
    },
    data () {
      return {
        courses: [],
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID']),
    },
    methods: {
      getClasses() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.courses = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
    },
    beforeMount() {
      this.getClasses();
    },
  }
</script>
